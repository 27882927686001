import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";

const Advantages = () =>{
    useEffect(()=>{
        AOS.init({duration:2000,
            easing: "ease-in-out"})
    },[])

    return(
        <div className='advantages-wrapper'>
            <div className='content-wrapper'>
                <div className='advantages-title'>
                    <h4 data-aos="fade-right">Почему выбирают нас?</h4>
                    <h3 data-aos="fade-left">Преимущества</h3>
                </div>
                <div className="advantages-context">
                    <ul className="advantages-left-block">
                        <li data-aos="fade-up">
                            <div className="label">
                                <div className="advantages-icon"/>
                                <span>Возможность страхования грузов</span>
                            </div>
                            <p>Застрахуем ваш груз от всех рисков.</p>
                        </li>
                        <li data-aos="fade-up">
                            <div className="label">
                                <div className="advantages-icon"/>
                                <span>Возможность консолидации грузов</span>
                            </div>
                            <p>Наличие консолидационных складов по всей Европе.</p>
                        </li>
                        <li data-aos="fade-up">
                            <div className="label">
                                <div className="advantages-icon"/>
                                <span>Консультация и помощь в оформлении экспортных документов</span>
                            </div>
                            <p>Поможем с экспортным оформлением.</p>
                        </li>
                        <li data-aos="fade-up">
                            <div className="label">
                                <div className="advantages-icon"/>
                                <span>Обширная база надежных партнеров и подрядчиков по всему миру</span>
                            </div>
                            <p>Организуем доставку из любой точки Мира.</p>
                        </li>
                    </ul>
                        <ul className="advantages-right-block">
                            <div data-aos="fade-in" duration="2000" className="advantages-img"/>
                            <li className="control" data-aos="fade-up">
                                <div className="label">
                                    <div className="advantages-icon"/>
                                    <span>Контроль груза на каждом этапе перевозки</span>
                                </div>
                            </li>
                            <li data-aos="fade-up">
                                <div className="label">
                                    <div className="advantages-icon"/>
                                    <span>Маркировка, стикеровка, фотографирование и взвешивание груза</span>
                                </div>
                                <p>При необходимости, сделаем всё за отправителя.</p>
                            </li>
                        </ul>
                </div>
            </div>
        </div>
    )
}

export default Advantages;
