import {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = () =>{
    useEffect(()=>{
        AOS.init({duration:1000,
            easing: "ease-in-out"})
    },[])

    return(
        <footer className="footer">
            <div className="content-wrapper">
                <div data-aos="fade-in" className="footer-logo"/>
                <div data-aos="fade-in" className="footer-block company">
                    <h3 className="footer-title">Компания</h3>
                    <span>© 2021 ООО "ТГК Логистик Групп"</span>
                    <span>УНП 193478467</span>
                    <span className="xl">Свидетельство о государственной</span>
                    <span className="xl">регистрации от 16.10.2020г.</span>
                    <span className="lg">Свидетельство о</span>
                    <span className="lg">государственной регистрации</span>
                    <span className="lg">от 16.10.2020г.</span>
                </div>
                <address data-aos="fade-in" className="footer-block address">
                    <h3 className="footer-title">Адрес</h3>
                    <span>220030 Беларусь, г.Минск</span>
                    <span>ул. Кирова 8, оф. 36</span>
                </address>
                <address data-aos="fade-in" className="footer-block company">
                    <h3 className="footer-title">Контакты</h3>
                    <a href="mailto:info@tgklogistic.by">info@tgklogistic.by</a>
                    <a href="tel:+375173363033">+375 17 336 30 33</a>
                    <a href="tel:+375173363033">+7 495 128 94 08</a>
                </address>
            </div>
            <div className="site-link-wrapper">
                <a target="_blank" href="https://miracle.by/" rel="noreferrer">Сайт разработан | Miracle.by</a>
            </div>
        </footer>
    )
}

export default Footer;
