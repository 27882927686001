import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";

const Map = () =>{
    useEffect(()=>{
        AOS.init({duration:2000,
            easing: "ease-in-out"})
    },[])

    return(
        <div className="map-wrapper">
            <div className="content-wrapper">
                <div className="map-title">
                    <h4 data-aos="fade-up">С какими маршрутами мы работаем?</h4>
                    <h3 data-aos="fade-up">Доставляем грузы по всему миру</h3>
                </div>
                <div data-aos="fade-in" duration="3500" className="map">
                    <img src="/assets/map1.svg" alt="map"/>
                </div>
        </div>
</div>
)
}

export default Map;
