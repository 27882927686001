import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";

const Processes = () =>{
    useEffect(()=>{
        AOS.init({duration:1500,
            easing: "ease-in-out"})
    },[])

    return(
        <div className='processes-wrapper'>
            <div className='content-wrapper'>
                <div className='processes-title'>
                    <h4 data-aos="fade-right">Как МЫ РАБОТАЕМ?</h4>
                    <h3 data-aos="fade-left">Процесс работы</h3>
                </div>
                    <ul className="processes-block">
                        <div className="icons-top">
                            <li data-aos="fade-up" className="card-wrapper">
                                <div className="card">
                                    <div className="processes-icon order"/>
                                    <p>Вы делаете заявку через сайт или по телефону</p>
                                    <span>ЭТАП 1</span>
                                </div>
                                <div className="arrow">
                                    <img src="assets/down.svg" alt="arrow"/>
                                </div>
                            </li>

                            <li data-aos="fade-up" className="card-wrapper">
                                <div className="card">
                                    <div className="processes-icon accounting"/>
                                    <p>Мы просчитываем ставку</p>
                                    <span>ЭТАП 2</span>
                                </div>
                                <div className="arrow">
                                    <img src="assets/down.svg" alt="arrow"/>
                                </div>
                            </li>

                            <li data-aos="fade-up" className="card-wrapper">
                                <div className="card">
                                    <div className="processes-icon contract"/>
                                    <p>Заключаем договор</p>
                                    <span>ЭТАП 3</span>
                                </div>
                                <div className="arrow">
                                    <img src="assets/down.svg" alt="arrow"/>
                                </div>
                            </li>
                        </div>
                        <div className="icons-bottom">
                            <li data-aos="fade-up" className="card-wrapper">
                                <div className="card">
                                    <div className="processes-icon pay"/>
                                    <p>Вы оплачиваете поставку</p>
                                    <span>ЭТАП 4</span>
                                </div>
                                <div className="arrow">
                                    <img src="assets/down.svg" alt="arrow"/>
                                </div>
                            </li>

                            <li data-aos="fade-up" className="card-wrapper">
                                <div className="card">
                                    <div className="processes-icon ship"/>
                                    <p>Мы осуществялем перевозку</p>
                                    <span>ЭТАП 5</span>
                                </div>
                                <div className="arrow">
                                    <img src="assets/down.svg" alt="arrow"/>
                                </div>
                            </li>

                            <li data-aos="fade-up" className="card-wrapper">
                                <div className="card">
                                    <div className="processes-icon cube"/>
                                    <p>Вы получаете товар</p>
                                    <span>ЭТАП 6</span>
                                </div>
                            </li>
                        </div>
                    </ul>
                <div data-aos="fade-in" className="tgk-label"/>
            </div>
        </div>
    )
}

export default Processes;
