const Slide = ({back, backMobile}) =>{

    return(
        <div>
            <div className='slide-wrapper desktop'
                 style={{
                     backgroundImage: back
                 }}>
            </div>
            <div className='slide-wrapper mobile'
                 style={{
                     backgroundImage: backMobile
                 }}>
            </div>
        </div>
    )
}

export default Slide;
