import React, {useEffect} from "react";
import {Link} from 'react-scroll'
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination, EffectFade, Autoplay, EffectCoverflow, Lazy} from "swiper";

import 'swiper/swiper-bundle.min.css'

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import Slide from "./Slide";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay, EffectCoverflow, Lazy]);


const ship = `linear-gradient(198.73deg, rgba(0, 0, 0, 0.0001) 15.31%, rgba(0, 0, 0, 0.837986) 67.37%), url('/assets/ship.png')`;
const car = `linear-gradient(198.73deg, rgba(0, 0, 0, 0.0001) 15.31%, rgba(0, 0, 0, 0.837986) 67.37%), url('/assets/car.png')`;
const train = `linear-gradient(276.78deg, rgba(0, 0, 0, 0.0001) -13.07%, rgba(0, 0, 0, 0.837986) 87.7%), url('/assets/train.png')`
const airplane = `linear-gradient(280.38deg, rgba(0, 0, 0, 0.0001) 13.07%, rgba(0, 0, 0, 0.837986) 67.16%), url('/assets/airplane.png')`;
const big = `linear-gradient(274.6deg, rgba(0, 0, 0, 0.0001) -14.18%, rgba(0, 0, 0, 0.837986) 78.65%), url('/assets/big-car.png')`

//mobile
const shipMobile = `linear-gradient(198.73deg, rgba(0, 0, 0, 0.0001) 15.31%, rgba(0, 0, 0, 0.837986) 67.37%), url('/assets/mobile/ship.png')`;
const carMobile = `linear-gradient(198.73deg, rgba(0, 0, 0, 0.0001) 15.31%, rgba(0, 0, 0, 0.837986) 67.37%), url('/assets/mobile/car.png')`;
const trainMobile = `linear-gradient(276.78deg, rgba(0, 0, 0, 0.0001) -13.07%, rgba(0, 0, 0, 0.837986) 87.7%), url('/assets/mobile/train.png')`
const airplaneMobile = `linear-gradient(280.38deg, rgba(0, 0, 0, 0.0001) 13.07%, rgba(0, 0, 0, 0.837986) 67.16%), url('/assets/mobile/airplane.png')`;
const bigMobile = `linear-gradient(274.6deg, rgba(0, 0, 0, 0.0001) -14.18%, rgba(0, 0, 0, 0.837986) 78.65%), url('/assets/mobile/big-car.png')`

//scr for preloader
const shipScr = '/assets/ship.png';
const carScr = '/assets/car.png';
const trainScr = '/assets/train.png';
const airplaneScr = '/assets/airplane.png';
const bigScr = '/assets/big-car.png';


export default function Header() {
    useEffect(() => {
        AOS.init({
            duration: 2000,
            easing: "ease-in-out"
        })
    }, [])

    return (
        <header className="header">
            <div className="header-title-wrapper">
                <div className="header-title">
                    <span data-aos="fade-up" className="xl">Расширяем границы</span>
                    <span data-aos="fade-up" className="xl">ваших возможностей</span>
                    <span data-aos="fade-up" className="lg">Расширяем</span>
                    <span data-aos="fade-up" className="lg">границы ваших</span>
                    <span data-aos="fade-up" className="lg">возможностей</span>
                    <span data-aos="fade-up" className="xs">Расширяем</span>
                    <span data-aos="fade-up" className="xs">границы</span>
                    <span data-aos="fade-up" className="xs">ваших</span>
                    <span data-aos="fade-up" className="xs">возможностей</span>
                    <h3 data-aos="fade-left" className="header-subtitle">Автомобильные, железнодорожные, авиа и морские
                        перевозки</h3>
                    <Link className="header-btn" to="contact" smooth={true}
                          duration={1000}>Оставить заявку</Link>
                </div>
            </div>
            <div data-aos="fade-in" duration="3500" className="header-top-block">
                <div className="header-logo"/>
                <address className="header-contacts">
                    <div className="header-email-wrapper">
                        <img className="mail-icon" src="assets/mail.svg" alt="phone"/>
                        <a href="mailto:info@tgklogistic.by">info@tgklogistic.by</a>
                    </div>
                    <div className="header-phone-wrapper">
                        <img className="phone-icon" src="assets/phone.svg" alt="phone"/>
                        <div className="header-phone">
                            <a href="tel:+375173363033">+375 17 336 30 33</a>
                            <a href="tel:+375173363033">+7 495 128 94 08</a>
                        </div>
                    </div>
                </address>
            </div>
            <Swiper
                effect="fade"
                loop="true"
                lazy={true}
                navigation
                pagination={{clickable: true, dynamicBullets: true}}
                fadeEffect={{
                    crossFade: true
                }}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                speed={2500}
                className="mySwiper">
                <SwiperSlide data-background={shipScr} className="swiper-lazy">
                    <Slide back={ship} backMobile={shipMobile}/>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
                <SwiperSlide data-background={carScr} className="swiper-lazy">
                    <Slide back={car} backMobile={carMobile}/>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
                <SwiperSlide data-background={trainScr} className="swiper-lazy">
                    <Slide back={train} backMobile={trainMobile}/>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
                <SwiperSlide data-background={airplaneScr} className="swiper-lazy">
                    <Slide back={airplane} backMobile={airplaneMobile}/>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
                <SwiperSlide data-background={bigScr} className="swiper-lazy">
                    <Slide back={big} backMobile={bigMobile}/>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
            </Swiper>
        </header>
    )
}
