import React from 'react';
import Map from './components/Map';
import Directions from './components/Directions';
import Advantages from './components/Advantages';
import Form from './components/Form';
import Processes from './components/Processes';
import Footer from './components/Footer';
//style
import "./styles/App.scss";
import Header from './components/Header';

function App() {
  return (
    <div className="App">
        <Header />
        <Directions/>
        <Advantages/>
        <Processes/>
        <Map/>
        <Form/>
        <Footer/>
    </div>
  );
}

export default App;
