import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";

const Directions = () =>{
    useEffect(()=>{
        AOS.init({duration:2000,
            easing: "ease-in-out"})
    },[])

    return(
        <div className='directions-wrapper'>
            <div className='content-wrapper'>
                <div className='directions-title'>
                    <h4 data-aos="fade-right">Что мы предлагаем?</h4>
                    <h3 data-aos="fade-left">Направления работы</h3>
                </div>
                <div className="cards-wrapper">
                    <ul className='top-block-cards'>
                    <li data-aos="fade-up" className='card car'>
                        <div className={`icon auto`}/>
                        <p className='text'>
                            <span>Автомобильные </span>
                            <span>комплектные </span>
                            <span>перевозки</span>
                        </p>
                    </li>
                    <li data-aos="fade-up" className='card mix'>
                        <div className={`icon mix`}/>
                        <p className='text'>
                            <span>Автомобильные </span>
                            <span>сборные </span>
                            <span>перевозки</span>
                        </p>
                    </li>
                    <li data-aos="fade-up" className='card '>
                        <div className={`icon el`}/>
                        <p className='text'>
                            <span>Перевозки </span>
                            <span>крупногабаритных </span>
                            <span>грузов</span>
                        </p>
                    </li>
                    <li data-aos="fade-up" className='card'>
                        <div className={`icon war`}/>
                        <p className='text'>
                            <span>Перевозки </span>
                            <span>опасных грузов</span>
                        </p>
                    </li>
                </ul>
                    <ul className='bottom-block-cards'>
                        <li data-aos="fade-up" className='card'>
                            <div className={`icon fly-car`}/>
                            <p className='text'>
                                <span>Мультимодальные </span>
                                <span>перевозки</span>
                            </p>
                        </li>
                        <li data-aos="fade-up" className='card'>
                            <div className={`icon fly`}/>
                            <p className='text'>
                                <span>Авиа </span>
                                <span>перевозки</span>
                            </p>
                        </li>
                        <li data-aos="fade-up" className='card'>
                            <div className={`icon train`}/>
                            <p className='text'>
                                <span>Железно-</span>
                                <span>дорожные </span>
                                <span>перевозки</span>
                            </p>
                        </li>
                        <li data-aos="fade-up" className='card'>
                            <div className={`icon ship`}/>
                            <p className='text'>
                                <span>Морские </span>
                                <span>перевозки</span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Directions;
